import { template as template_87c6fda6323441da97a344cb29a4055f } from "@ember/template-compiler";
const FKLabel = template_87c6fda6323441da97a344cb29a4055f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
