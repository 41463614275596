import { template as template_11134c87f8e7476d8b0c95cf317b77b4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_11134c87f8e7476d8b0c95cf317b77b4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
