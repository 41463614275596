import { template as template_fca39f9363b345c19c8693d95bde3e0d } from "@ember/template-compiler";
const WelcomeHeader = template_fca39f9363b345c19c8693d95bde3e0d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
